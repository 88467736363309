import React, { Fragment, useEffect, useRef, useState } from "react";
import * as ReactDOM from 'react-dom';

import JqxTable from "../../../../share-components/table/JqxTable";

import JqxGrid, { IGridProps, jqx } from './../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid';
import * as ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { formatterDecimal, formatter } from "../../../../utils/common";

const ItemsTable = (props) => {
    const { orderItems, orderState, isDirectRefund, setOrderItems } = props;

    let gridRef = useRef(null);

    // eslint-disable-next-line no-shadow
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;
            jqx.selectallrows();
        }
    };

    const ReasonSource = {
        datatype: 'array',
        datafields: [
            { name: 'id', type: 'string' },
            { name: 'name', type: 'string' }
        ],
        localdata: orderState.settings.returnReasons,
    };

    const reasonAdapter = new jqx.dataAdapter(ReasonSource, { autoBind: true });

    const dataFields = [
        { name: 'id', type: 'id' },
        { name: 'selected', type: 'bool' },
        { name: 'productId', type: 'string' },
        { name: 'rowExpand', type: 'bool' },
        { name: 'productVariantId', type: 'string' },
        { name: 'quantity', type: 'number' },
        { name: 'availableQuantity', type: 'number' },
        { name: 'name', type: 'string' },
        { name: 'price', type: 'number' },
        { name: 'priceFormat', type: 'string' },
        { name: 'isRestock', type: 'bool' },
        { name: 'isPack', type: 'bool' },
        { name: 'packSinglePrice', type: 'number' },
        { name: 'image', type: 'string' },
        { name: 'total', type: 'string' },
        { name: 'returnTotal', type: 'number' },
        { name: 'returnTotalFormat', type: 'string' },
        { name: 'reason_id', type: 'string' },
        { name: 'reason', value: 'reason_id', values: { source: reasonAdapter.records, value: 'id', name: 'name' } },
        { name: 'return_request_id', type: 'number' },
        { name: 'made_to_measure_request_id', type: 'number' },
    ];

    const columns = [
        { datafield: 'id', hidden: true },
        { datafield: 'productId', hidden: true },
        { datafield: 'isPack', hidden: true },
        { datafield: 'packSinglePrice', hidden: true },
        { datafield: 'productVariantId', hidden: true },
        {
            text: '',
            datafield: 'selected',
            columntype: 'checkbox',
            width: 50,
            editable: true,
            cellbeginedit(row, datafield, columntype) {                
                if(gridRef.getcellvalue(row,'return_request_id') || gridRef.getcellvalue(row,'made_to_measure_request_id')){
                    return false;
                }
                
                return true;
            },
            cellendedit(row, datafield, columntype, value) {
                if (!value) {
                    let totalAmount = 0;
                    gridRef.setcellvalue(row, 'reason', '');
                    gridRef.setcellvalue(row, 'reason_id', '');
                    gridRef.setcellvalue(row, 'quantity', 0);
                    gridRef.setcellvalue(row, 'returnTotal', totalAmount);
                    gridRef.setcellvalue(row, 'returnTotalFormat', formatterDecimal.format(totalAmount));
                    gridRef.setcellvalue(row, 'isRestock', false);
                }
            },
        },
        {
            text: 'Product Name',
            datafield: 'name',
            width: '33%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = orderState.list[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{ textAlign: 'center', padding: 5 }}>
                        {productDetails && <div className="row">
                            <div className="col-md-3">
                                <img className="img-fluid" src={productDetails.image} width="40" alt="" />
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <p className={'font-primary'}>{value}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.colour}/{productDetails.size}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.sku}</p>
                                </div>
                                
                                {productDetails.return_request_id && (
                                    <div style={{textAlign: 'left'}} className="row">
                                        <p className="text-danger">
                                            Customer has already raised return request for this item. <br/>
                                            <a target="_blank" style={{fontWeight: 'bold'}} href={`${process.env.PUBLIC_URL}/order/pending-return-requests/${productDetails.return_request_id}/edit`}>Click here</a> to check that request.
                                        </p>
                                    </div>
                                )}

                                {productDetails.made_to_measure_request_id && (
                                    <div style={{textAlign: 'left'}} className="row">
                                        <p className="text-danger">
                                            Customer has already raised Alteration request for this item. <br/>
                                            <a target="_blank" style={{fontWeight: 'bold'}} href={`${process.env.PUBLIC_URL}/order/alteration-requests/${productDetails.made_to_measure_request_id}/view`}>Click here</a> to check that request.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>}
                    </div>
                );
                return html;
            },
        },
        {
            text: 'Price (£)',
            datafield: 'price',
            displayfield: 'priceFormat',
            width: '10%',
            editable: false,
            columntype: 'numberinput',
            cellsformat: 'c2',
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) { },
        },
        {
            text: 'Order Qty',
            datafield: 'availableQuantity',
            width: '10%',
            editable: false,
            columntype: 'numberinput',
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) { },
        },
        {
            text: '<strong>Return Qty <strong> <span style="color: red">*</span>',
            datafield: 'quantity',
            editable: true,
            width: '8%',
            align: 'center',
            cellsalign: 'center',
            cellsformat: 'n',
            cellbeginedit(row, datafield, columntype, val) {
                if(gridRef.getcellvalue(row,'return_request_id') || gridRef.getcellvalue(row,'made_to_measure_request_id')){
                    return false;
                }

                let rowData = gridRef.getrowdata(row);
                let found = 0;
                gridRef.getrows().forEach(element => element.id === rowData.id && rowData.selected === true ? found++ : "");
                return (found > 0);
            },
            cellendedit(row, datafield, columntype, oldvalue, value) {
                let totalAmount = value * gridRef.getcellvalue(row, 'price');
                gridRef.setcellvalue(row, 'returnTotal', totalAmount);
                gridRef.setcellvalue(row, 'returnTotalFormat', formatterDecimal.format(totalAmount));
            },
            validation(cell, value) {
                if (value !== "") {
                    if (value > gridRef.getcellvalue(cell.row, 'availableQuantity')) {
                        toast.error(`Return quantity cannot exceed the ordered quantity - (${gridRef.getcellvalue(cell.row, 'availableQuantity')})`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return { result: false, message: `Return quantity cannot exceed the ordered quantity - (${gridRef.getcellvalue(cell.row, 'availableQuantity')}).` };
                    }
                    if (parseFloat(value) === 0 && !gridRef.getcellvalue(cell.row, 'isPack')) {
                        toast.error(`Please enter valid quantity`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return { result: false, message: `Please enter valid quantity.` };
                    }

                    if (!Number.isInteger(parseFloat(value))) {
                        toast.error(`Quantity cannot be decimal`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return { result: false, message: `Quantity cannot be decimal.` };
                    }
                } else {
                    toast.error(`Please enter valid quantity`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return { result: false, message: `Please enter valid quantity.` };
                }

                return true;
            }
        },
        {
            text: 'Return Total (£)' + (isDirectRefund ? ' <span style="color: red">*</span>' : ''),
            // text: 'Return Total (£)',
            datafield: 'returnTotal',
            displayfield: 'returnTotalFormat',
            width: '12.1%',
            editable: isDirectRefund ? true : false,
            // editable: false,
            cellsformat: 'c2',
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) {                
                if(gridRef.getcellvalue(row,'return_request_id') || gridRef.getcellvalue(row,'made_to_measure_request_id')){
                    return false;
                }

                let rowData = gridRef.getrowdata(row);
                let found = 0;
                gridRef.getrows().forEach(element => element.id === rowData.id && rowData.selected === true && rowData.quantity > 0 ? found++ : "");
                return (found > 0);
            },
            cellendedit(row, datafield, columntype, oldvalue, value) {
                gridRef.setcellvalue(row, 'returnTotalFormat', formatterDecimal.format(value));
            },
            validation(cell, value) {
                if (value !== "") {
                    let totalAmount = gridRef.getcellvalue(cell.row, 'quantity') * gridRef.getcellvalue(cell.row, 'price');
                    if (parseFloat(totalAmount) < parseFloat(value)) {
                        toast.error(`The total amount should be equal or less than ${formatter.format(totalAmount)}.`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return { result: false, message: `The total amount should be equal or less than ${formatter.format(totalAmount)}.` };
                    }

                }else {
                    toast.error(`Please enter valid quantity`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                    return false;
                }

                return true;
            }
        },
        {
            text: ' <strong>Reason</strong> <span style="color: red">*</span>',
            datafield: 'reason_id',
            displayfield: 'reason',
            width: '15%',
            align: 'left',
            columntype: 'dropdownlist',
            editable: true,
            cellbeginedit(row, datafield, columntype, val) {
                if(gridRef.getcellvalue(row,'return_request_id') || gridRef.getcellvalue(row,'made_to_measure_request_id')){
                    return false;
                }

                let rowData = gridRef.getrowdata(row);
                let found = 0;
                gridRef.getrows().forEach(element => element.id === rowData.id && rowData.selected === true ? found++ : "");
                return (found > 0);
            },
            createeditor(row, value, editor) {
                editor.jqxDropDownList({ height: 75, source: reasonAdapter, displayMember: 'name', valueMember: 'id' });
            },
            validation(cell, value) {
                const reasons = orderState.settings.returnReasons;
                let selectedReason = reasons.find(item => item.id === Number(value.value));
                gridRef.setcellvalue(cell.row, 'isRestock', selectedReason.is_restock === "Yes");
                if (orderState.data.is_return) {
                    gridRef.setcellvalue(cell.row, 'isDecline', false);
                }
                return true;
            }
        },
        {
            text: 'Is Restock',
            datafield: 'isRestock',
            editable: true,
            width: '8%',
            align: 'center',
            cellsalign: 'right',
            columntype: 'checkbox',
            cellbeginedit(row, datafield, columntype, val) {
                if(gridRef.getcellvalue(row,'return_request_id') || gridRef.getcellvalue(row,'made_to_measure_request_id')){
                    return false;
                }

                let rowData = gridRef.getrowdata(row);
                let found = 0;
                gridRef.getrows().forEach(element => element.id === rowData.id && rowData.selected === true ? found++ : "");
                return (found > 0);
            },
            cellendedit(row, datafield, columntype, oldvalue, value) { }
        }
    ];

    const handleCellValueChanged = (event) => {
        let items = gridRef.getrows().map(item => ({...item, reason: item.reason_id ? item.reason : ''}));
        setOrderItems(items);
    };

    // const handleRowSelect = (event) => {
    //     gridRef.setcellvalue(event.args.rowindex, 'selected', true);
    // };

    // const handleRowUnselect = event => {
    //     gridRef.setcellvalue(event.args.rowindex, 'reason', '');
    //     gridRef.setcellvalue(event.args.rowindex, 'reason_id', '');
    //     gridRef.setcellvalue(event.args.rowindex, 'quantity', 0);
    //     gridRef.setcellvalue(event.args.rowindex, 'returnTotal', 0);
    //     gridRef.setcellvalue(event.args.rowindex, 'returnTotalFormat', formatterDecimal.format(0));
    //     gridRef.setcellvalue(event.args.rowindex, 'selected', false);
    //     gridRef.setcellvalue(event.args.rowindex, 'isRestock', false);
    // };

    // Apply selected rows on grid initialization or page change
    useEffect(() => {
        if (gridRef) {
            gridRef.clearselection();
            orderItems.forEach((row, i) => {
                if (row.selected === true) {
                    gridRef.selectrow(i);
                }
            });
        }
    }, [orderItems]);

    return (
        <Fragment>
            <JqxTable
                datafields={dataFields}
                columns={columns}
                data={orderItems}
                myGrid={gridRef}
                isDelete={false}
                disableCreateRow
                autorowheight={true}
                getJqxRef={getJqxRef}
                onCellvaluechanged={handleCellValueChanged}
                // onRowselect={handleRowSelect}
                // onRowunselect={handleRowUnselect}
                // selectionmode={'checkbox'}
            />
        </Fragment>
    );
}


function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) == JSON.stringify(nextProps);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(ItemsTable, areEqual);