import * as yup from "yup";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const phoneRegExp =/^[0-9]{10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }else{
            return true;
        }
        return  false;
    });
});

yup.addMethod(yup.string, "customMatch", function (errorMessage, input) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            if (input === 'email') {
                return  this.parent.email !== value;
            }else{
                return  this.parent.number !== value;
            }
        }
        return  true;
    });
});
export const defaultValues = {
    name: '',
    shopName: '',
    email: '',
    number: '',
    pdCity: '',
    pdCountry: '',
    isPremium: false,
    userID: '',
    commission: '',
    location: '',

    storeDescriptionHeading: '',
    storeDescription: '',
    sellerDescriptionHeading: '',
    sellerDescription: '',
    deliveryDays: '',
    deliveryMethodName: '',
    deliveryTime: '',
    otherInformationHeading: '',
    otherInformation: '',
    returnDays: '',
    refundDays: '',
    sellerSince: '',
    otherPolicyHeading: '',
    otherPolicy: '',

    minOrder:'',
    deliveryInfo:'',
    chargeTax: false,
    socialFacebook: '',
    socialTwitter: '',
    socialInstagram: '',
    instagramWidgetCode: '',
    shippingOption: 1,

    returnCancellationPolicyHeading: '',
    returnCancellationPolicy: '',
    sellerProfileHeading: '',
    sellerProfileLink: '',
    sellerShopHeading: '',
    sellerShopLink: '',
    brandSizeChartHeading: '',
    brandSizeChartLink: '',
    sizeChartHeading: '',
    sizeChartLink: '',
    promotionHeading: '',
    promotionLink: '',
    tradeShowHeading: '',
    tradeShowLink: '',
    designedMadeInHeading: '',
    designedMadeInLink: '',
    shippingValidityHeading: '',
    shippingValidityLink: '',

    customerFullName: '',
    customerMobileNumber: '',
    customerEmail: '',

    returnsFirstAddress: '',
    returnsCity: '',
    returnsCountry: '',
    returnsPostcode: '',

    storeName: '',
    apiKey: '',
    secretKey: '',
};

/**
 * Form Validation Schema
 */

const urlRegex = /^(?!https?:\/\/)(?!www\.)[a-zA-Z0-9-.]+\.([a-zA-Z]{2,}\.)?[a-zA-Z]{2,}$/;

export const schema = yup.object().shape({
    name: yup.string().required('Name is required field'),
    shopName: yup.string().required('Shop Name is required field'),
    email: yup.string().email().required('Email is required field').typeError('Please enter valid mail'),
    number: yup.string().required('Phone number is required field').customPhoneValidation('Invalid Phone number'),
    pdCity: yup.string().nullable(),
    isPremium: yup.bool().nullable(),
    pdCountry: yup.object().required('Country is required field').typeError('Please select Country'),
    // location: yup.object().required('Location is required field').typeError('Please select Location'),
    // commission: yup.number().required('Commission is a required field').typeError('Please enter valid input for Commission'),

    storeDescriptionHeading: yup.string().nullable(),
    storeDescription: yup.string().required('Store Description is required field'),
    sellerDescriptionHeading: yup.string().nullable(),
    sellerDescription: yup.string().required('Store Description is required field'),
    deliveryDays: yup.string().nullable(),
    deliveryMethodName: yup.string().nullable(),
    deliveryTime: yup.string().nullable(),
    otherInformationHeading: yup.string().nullable(),
    otherInformation: yup.string().nullable(),
    returnDays: yup.number().nullable(),
    refundDays: yup.number().nullable(),
    sellerSince: yup.string().required('Seller Since is required field'),
    otherPolicyHeading: yup.string().nullable(),
    otherPolicy: yup.string().required('Other Policy is required field'),


    minOrder: yup.number().required('Minimum order is required field').typeError('Please enter valid input for minimum order')  ,
    deliveryInfo: yup.string().nullable(),
    chargeTax: yup.bool().nullable(),
    socialFacebook: yup.string().nullable(),
    socialTwitter: yup.string().nullable(),
    socialInstagram: yup.string().nullable(),
    instagramWidgetCode: yup.string().nullable(),
    shippingOption: yup.string().required('Shipping Option Description is required field'),

    returnCancellationPolicyHeading: yup.string().nullable(),
    returnCancellationPolicy: yup.string().nullable(),
    sellerProfileHeading: yup.string().nullable(),
    sellerProfileLink: yup.string().nullable(),
    sellerShopHeading: yup.string().nullable(),
    sellerShopLink: yup.string().nullable(),
    brandSizeChartHeading: yup.string().nullable(),
    brandSizeChartLink: yup.string().nullable(),
    sizeChartHeading: yup.string().nullable(),
    sizeChartLink: yup.string().nullable(),
    promotionHeading: yup.string().nullable(),
    promotionLink: yup.string().nullable(),
    tradeShowHeading: yup.string().nullable(),
    tradeShowLink: yup.string().nullable(),
    designedMadeInHeading: yup.string().nullable(),
    designedMadeInLink: yup.string().nullable(),
    shippingValidityHeading: yup.string().nullable(),
    shippingValidityLink: yup.string().nullable(),

    customerFullName: yup.string().nullable(),
    customerEmail: yup.string().nullable().email('Please enter valid mail'),
    customerMobileNumber: yup.string().nullable().customPhoneValidation('Invalid Phone number'),

    returnsFirstAddress: yup.string().required('Address is required field').typeError('Address is required field'),
    returnsCity: yup.string().required('City/Town is required field').typeError('City/Town is required field'),
    returnsCountry: yup.object().required('Country is required field').typeError('Country is required field'),
    returnsPostcode: yup.string().required('Postcode is required field').typeError('Postcode is required field'),

    // storeName: yup.string().nullable(),
   storeName: yup.string().nullable()
    .test('is-url-or-null', 'Please enter a valid URL without https, http, or www. and with a valid domain extension like .com, .lk, .uk, etc.', (value) => {
        return value === null || value === '' || urlRegex.test(value);
      }),
    apiKey: yup.string().nullable(),
    secretKey: yup.string().nullable(),
});

